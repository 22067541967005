.App {
  background-size: cover !important;
  height: 100vh !important;
  background-position: center center !important;
}
.content {
  margin: 0;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
}
.socialButtons {
  display: flex;
  flex-direction: column;
}

.socialButtons .button {
  margin: 5px 0 !important;
}

.inputError {
  border: 1px solid red !important;
}
.errorLabel {
  color: red !important;
  font-size: 0.8em !important;
}

.logo {
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo img {
  max-width: 50px;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  

  
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  

  
}